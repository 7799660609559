<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Announcement Settings</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="client-portal-setting-form" ref="announcement-settings-form">
                <div class="modal_body">
                    <div class="tabs_content">
                        <div class="section_content w-100" v-show="type == 2">
                            <div class="toggle_content">
                                <div class="color_container">
                                    <label for="announcement" class="switch_option capsule_btn">
                                        <h5>Show</h5>
                                        <input type="checkbox" id="announcement" :true-value="1" :false-value="0" v-model="form.is_enabled" @change="handleAutoSave" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <Transition duration="550" name="nested">
                                        <div v-if="form.is_enabled">
                                            <div class="setting_wpr">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Widget Title <a class="reset-default" @click="resetDefault('title')">Reset to default</a></label>
                                                        <div class="field_wpr m-0">
                                                            <Field autocomplete="off" type="text" name="announcement_widget_title" v-model="form.title" placeholder="ex: What's new!" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Background Color <a class="reset-default" @click="resetDefault('bg-color')">Reset to default</a></label>
                                                        <Field autocomplete="off" name="announcement_bg_color" v-model="form.bg_color" type="text" label="background color">
                                                            <color-picker v-model="form.bg_color" :classes="{ 'has-error': errors.announcement_bg_color }" />
                                                        </Field>
                                                        <ErrorMessage name="announcement_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Text Color <a class="reset-default" @click="resetDefault('text-color')">Reset to default</a></label>
                                                        <Field autocomplete="off" name="announcement_text_color" v-model="form.text_color" type="text" label="background color">
                                                            <color-picker v-model="form.text_color" :classes="{ 'has-error': errors.announcement_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="announcement_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="announcementSettingUpdateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="announcementSettingUpdateLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="announcementSettingUpdateLoader"></i> {{ announcementSettingUpdateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import constants from '@/utils/Constant'

export default {
    name: 'announcement-setting',

    data () {
        return  {
            session_label: 1,
            selectedCoach: 0,
            form: {
                title: '',
                is_enabled: 0,
                bg_color: '',
                title_text_color: '',
                text_color: '',
                message: '',
                has_include_link: 0,
                announcement_title: '',
                link_text: '',
                link: '',
            },
            announcementDefaultmessage: 'Share Your Latest News! Craft a Captivating Announcement for Your Audience.',
            loaded: false,
            announcementMessageResetWatcher: 0,
            announcementMessageResetToDefautWatcher: 0,
        };
    },

    props: {
        modelValue: Boolean,
        type: Number,
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue (val) {
            if (val) {
                const vm = this;
                vm.resetForm();

                setTimeout(() => {
                    vm.announcementMessageResetWatcher ++;
                }, 600);

                document.body.classList.add('modal-open');

                setTimeout(function () {
                    vm.loaded = true;
                }, 2000);
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },

    components: {
        Field,
        Form,
        ErrorMessage,
    },

    computed: mapState({
        clientPortal: state => state.clientPortalModule.clientPortal,
        announcementSettingUpdateLoader: state => state.clientPortalModule.announcementSettingUpdateLoader,
    }),

    mounted () {
            const vm = this;
        },

    methods: {
        ...mapActions({
            updateAnnouncementSetting: 'clientPortalModule/updateAnnouncementSetting',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        resetForm () {
            const vm = this;

            vm.form = vm.clientPortal.announcement_settings.length ? JSON.parse(JSON.stringify(vm.clientPortal.announcement_settings[0])) : [];

            vm.announcementDefaultmessage = vm.form.message;

            setTimeout(() => {
                vm.announcementMessageResetWatcher++;
            }, 1000)
        },

        handleSubmit (form, { setFieldError }) {
            const vm = this;
            const params = JSON.parse(JSON.stringify(vm.form));

            vm.updateAnnouncementSetting(params).then((result) => {
                if (result) {
                    vm.closeModal();
                }
            });
        },

        handleAutoSave () {
            const vm = this;
            const params = JSON.parse(JSON.stringify(vm.form));

            const form = vm.$refs['announcement-settings-form'];

            params.setFieldError = form.setFieldError;

            vm.updateAnnouncementSetting(params);
        },

        resetDefault (field) {
            const vm = this;

            if (field == 'title') {
                vm.form.title = 'What\'s New!';
            }

            if (field == 'bg-color') {
                vm.form.bg_color = '#D4D4D4';
            }

            if (field == 'text-color') {
                vm.form.text_color = '#393B3F';
            }

            if (field == 'announcement-text') {
                vm.form.announcement_text = 'New Announcements';
            }

            if (field == 'message') {
                vm.form.message = 'Share Your Latest News! Craft a Captivating Announcement for Your Audience.';

                vm.announcementMessageResetToDefautWatcher++;
            }

            if (field == 'link-color') {
                vm.form.link_color = '#2F7FED';
            }
        },
    },
};
</script>

<style scoped>
    .modal_body .tab_content{
        height: 100%;
    }
    .modal_body .tab_content :deep(.setting_wpr){
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .modal_body .tab_content :deep(.setting_wpr .section_wpr:last-of-type){
        margin-top: auto;
        padding-bottom: 50px;
    }

    .modal_container {
        height: 100% !important;
    }

    .client-portal-setting-form {
        height: calc(100% - 153px);
    }
</style>
